<template>
    <div style="height:100%;min-height: 500px;">
        <div style="width: 100%;" v-if="typeof content.user!='undefined'">
            <!-- 头像 -->
            <div @click="circle(content.user.uuid)" claas="hand op sc" style="width: 56px;height: 56px;margin: 24px 10px 0 26px;float: left;cursor: pointer">
                <img :src="content.user.headIco" style="width: 56px;height: 56px;border-radius: 50%">
            </div>
            <div style="display:flex;width: 65%;">
                <!-- 名称 -->
                <div @click="circle(content.user.uuid)" claas="hand op sc" style="font-size: 17px;height: 20px;margin-top: 30px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer">{{content.user.nickName}}</div>
                <!-- 大V -->
                <div v-if="content.user.v == 1" style="width: 20px;height: 20px;margin-top: 32px;margin-left: 5px">
                    <img src="../../../assets/imgs/index/vipBig.png" style="width: 20px; height: 20px;"/>
                </div>
                <!-- 专业  -->
                <div v-if="content.user.authenticationStatus == 2" style="width: 55px; height: 23px;margin-top: 30px;margin-left: 5px">
                    <img  src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/major.png" style="width: 55px; height: 23px;"/>
                </div>
                <!-- 专家 -->
                <div v-if="content.user.expertAuthStatus == 2" style="width:55px; height: 23px;margin-top: 30px;margin-left: 5px">
                    <img  src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/inoc.png" style="width:55px; height: 23px;"/>
                </div>
            </div>
            <div style="width: 240px;border-top: 1px solid #DCDCDC;text-align: center;float: left;margin: 15px 30px"></div>
            <div style="width: 100%;margin-left: 5px;display: flex;justify-content: center">
                <div style="width: 30%;float: left;">
                    <div style="height: 20px;font-size: 16px;text-align: center">{{content.user.fansNum}}</div>
                    <div style="font-size: 14px;color: #999999; height: 20px;text-align: center">粉丝</div>
                </div>
                <div style="width: 30%;float: left;">
                    <div style="height: 20px;font-size: 16px;text-align: center">{{content.user.publishArticleNum}}</div>
                    <div style="font-size: 14px;color: #999999; height: 20px;text-align: center">文章</div>
                </div>
                <div style="width: 30%;float: left;">
                    <div style="height: 20px;font-size: 16px;text-align: center">{{content.user.publishArticleUpNum}}</div>
                    <div style="font-size: 14px;color: #999999; height: 20px;text-align: center">点赞量</div>
                </div>
            </div>
            <div style="width: 240px;border-top: 1px solid #DCDCDC;text-align: center;float: left;margin: 15px 30px"></div>
            <div style="background: #031F88;width: 6px;height: 18px;float: left;margin-left: 32px;"></div>
            <div style="width:72px;height: 18px;font-size: 17px;float: left;margin: -2px 5px;">最近文章</div>
            <div v-if="contentList && contentList.length > 0" style="height: 400px;">
                <div style="clear: both;padding-top: 5px;margin-left: 10px;" v-for="(article,label) in contentList" :key="label">
                    <div v-if="content.id != article.id">
                        <div v-if="$route.params.type != 3">
                            <div v-if="label < 4" @click="Recommended(article.id)" class="op sc hand" style="margin: 10px 30px;">
                                <div style="font-size: 15px;cursor: pointer">{{article.title}}</div>
                                <div style="height: 16px;font-size: 14px;color: #999999;cursor: pointer">{{article.createTime}}</div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="label < 4" @click="Recommended(article.id)" class="op sc hand" style="margin: 10px 30px;">
                                <div style="font-size: 15px;cursor: pointer">{{article.title}}</div>
                                <div style="height: 16px;font-size: 14px;color: #999999;cursor: pointer">{{article.time}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="height: 400px;">
                <el-empty style="width: 300px;" description="暂无数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },

    computed: {
        us() {
            return this.$store.state.user;
        },
    },
    props:{
        content:{
            type:Object,
            default(){
                return false
            }
        },
    },
    data () {
        return {
          contentList:[],
        }
    },
    mounted:function(){
        if(this.content){
            if (this.$route.params.type == 3) {
                this.getTodayHeadlines();
            }else if (this.$route.params.type == 2){
                this.getFilebaseList()
            }else if (this.$route.params.type == 1) {
                this.getSpaceContent()
            }
        }
    },
    methods:{
        // 查询今日头条列表
        getTodayHeadlines(){
            var that = this;
            this.newApi.getTodayHeadlines({}).then((res)=>{
                that.contentList = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 查询知识仓库的列表
        getFilebaseList:function (){
            var that = this;
            this.newApi.getFilebaseList({
                type:that.$route.params.type,
                bgbType:6,
                pageSize:6,
                page:1,
            }).then((res)=>{
                that.contentList = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        Recommended(id){
            window.location.href = '/content/info/'+id+'/'+this.$route.params.type
        },
        // 朋友圈推荐内容
        getSpaceContent(){
            var that = this;
            this.newApi.getSpaceContent({
                uuid: that.us.uuid,
                type: 1,
                page: 1,
                pageSize: 6,
                bgbType: 2,
            }).then((res)=>{
                that.contentList = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 跳转到个人空间
        circle(uuid){
            window.open('/circle/mySpace/'+uuid)
        }

    }
}
</script>

<style scoped>

</style>
