<template>
    <div v-if="$route.params.type != 3" style="width: 100%;height: 100%;">
        <div v-if="is_login === 0">
            <div @click="clickLike" style="display: flex;justify-content: center;cursor: pointer;width: 35px;height: 35px;">
                <el-badge :value="content.thumbsUpCount" class="item">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon88.png" style="width: 35px;height: 35px;">
                </el-badge>
            </div>
        </div>
        <div v-if="is_login === 1">
            <div v-if="content.isThumbsUp === 0" @click="clickLike" style="display: flex;justify-content: center;cursor: pointer;width: 35px;height: 35px;">
                <el-badge :value="content.thumbsUpCount" class="item">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon88.png" style="width: 35px;height: 35px;">
                </el-badge>
            </div>
            <div v-else @click="clickLike" style="display: flex;justify-content: center;cursor: pointer;width: 35px;height: 35px;">
                <el-badge :value="content.thumbsUpCount" class="item">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon77.png" style="width: 35px;height: 35px;">
                </el-badge>
            </div>
        </div>
        <div @click="showComment()" style="display: flex;justify-content: center;margin-top: 20px;cursor: pointer;width: 35px;height: 35px;">
             <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon33.png" style="width: 35px;height: 35px;">
        </div>
       <div v-if="is_login === 1">
           <div v-if="content.isCollection == 0" @click="showLabel = true" style="display: flex;justify-content: center;margin-top: 20px;cursor: pointer;width: 35px;height: 35px;">
               <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon44.png" style="width: 35px;height: 35px;">
           </div>
           <div v-else @click="cancelCollection" style="display: flex;justify-content: center;margin-top: 20px;cursor: pointer;width: 35px;height: 35px;">
               <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon66.png" style="width: 35px;height: 35px;">
           </div>
       </div>
        <div v-if="is_login === 0">
            <div  @click="loginicon" style="display: flex;justify-content: center;margin-top: 20px;cursor: pointer;width: 35px;height: 35px;">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon44.png" style="width: 35px;height: 35px;">
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" :visible.sync="showLabel">
            <div style="padding: 20px; display: flex">
                <span style="font-size: 18px;font-weight: 600;margin-top: 8px">标签名： </span>
                <el-input style="width: 400px" v-model="labelName"></el-input>
            </div>
            <div style="display: flex; justify-content: end">
                <el-button class="data_type" @click="cancelLabel">取消</el-button>
                <el-button class="data_list" @click="saveLabel">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Collection",
        props: {
           content: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        data() {
            return {
                labelName: '',
                showLabel: false,
            }
        },

        mounted() {
        },
	    
        methods: {
	        showComment() {
                if (this.is_login === 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
                }
		        this.$emit('updateShowComment', true);
	        },

            // 点赞、取消点赞
            clickLike() {
	            if (this.is_login === 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
                }
                this.newApi.thumbsUp({
                    id: this.content.id,
                    type: this.content.bgbType
                }).then(res =>{
                    if (res.isSuccess == 1) {
                        if (this.content.isThumbsUp === 0) {
                            this.content.thumbsUpCount += 1;
                            this.content.isThumbsUp = 1
                        } else {
                            this.content.thumbsUpCount -= 1;
                            this.content.isThumbsUp = 0
                        }
                    }
                })
            },

            // 保存标签
            saveLabel() {
                this.newApi.addCollection({
                    bgbType: this.content.bgbType,
                    label: this.labelName,
                    id: this.content.id
                }).then(res =>{
                    if (res.isSuccess == 1) {
                        this.labelName = '';
                        this.showLabel = false;
                        this.content.isCollection = 1;
                        this.utils.sus(res.data)
                    }
                })
            },

            // 取消标签
            cancelLabel() {
                this.labelName = '';
                this.showLabel = false;
            },
            skiptemplate() {
                const targetElement = document.getElementById('target');
                const targetPosition = targetElement.offsetTop;
                const windowHeight = window.innerHeight;
                const scrollToPosition = targetPosition - (windowHeight / 2 - targetElement.offsetHeight / 2) + 500; // 往下多滚动500像素
                window.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth',
                });
            },
            // 取消收藏
            cancelCollection(){
                this.newApi.cancelCollection({
                    id: this.content.id,
                    bgbType: this.content.bgbType,
                }).then(res =>{
                    if (res.isSuccess == 1) {
                        this.content.isCollection = 0;
                        this.utils.sus(res.data)
                    }
                })
            },
            // 收藏未登录点击
            loginicon(){
                this.$store.commit('setShowLoginPopup', true);
            }
        },
    }
</script>

<style scoped>
/deep/ .el-badge__content.is-fixed{
    top: 1px;
    right: 16px;
}
</style>
