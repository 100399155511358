<template>
    <!-- 顶部 -->
    <div>
        <div style="width: 100%;height: 60px;background: #031F88;overflow:hidden;">
            <!--   log  -->
            <div style="width: 120px;height: 38px;margin: 10px 30px;" @click="add_return">
                <img src="https://oss.baigongbao.com/2021/02/04/Cr3y7bZDaF.png" style="width: 120px;height: 38px;">
            </div>
            <div v-if="type == 2"  style="display: flex;justify-content: center;align-content: center;margin-top: -42px">
                <div style="margin: 2px 5px;align-content: center">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon3.png" style="width: 18px;height: 22px;">
                </div>
                <div style="font-size: 18px;color: #FFFFFF;font-weight: bold;">资料详情</div>
            </div>
            <div  v-else style="display: flex;justify-content: center;align-content: center;margin-top: -42px">
                <div style="margin: 5px;align-content: center">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/knowledge.png" style="width: 22px;height: 18px;">
                </div>
                <div style="font-size: 18px;color: #FFFFFF;font-weight: bold;">文章详情</div>
            </div>
        </div>
        <div style="display: flex;justify-content: center;width: 100%;background: #FFFFFF;">
            <div style="width: 50px;height: 100%;margin-top: 110px;">
                <Collection :content="content"  @updateShowComment="updateShowComment"></Collection>
            </div>
            <!--  文章内容   -->
            <div style="width: 1200px;height: 100%;background: #FFFFFF;min-height: 1000px;">
                <articleDetailsPage :content="content" :parentValue.sync="parentValue"></articleDetailsPage>
            </div>
            <!-- 推荐信息-->
            <div style="width: 300px;border: 1px solid #DCDCDC;margin-left: 20px;height: 680px;margin-top: 40px;">
                <articlePersonalInformation :content="content"></articlePersonalInformation>
            </div>
        </div>
	    <div v-if="showComment" v-show="heightComment" class="custom-dialog" v-draggable  :style="{ top: dialogPosition.y + 'px', left: dialogPosition.x + 'px' }" >
		    <div class="custom-dialog-header" >
			    <span class="custom-dialog-title">可拖拽评论窗口</span>
			    <span class="custom-dialog-close" @click="showComment = false">×</span>
		    </div>
		    <div class="custom-dialog-body">
			    <el-input
				    type="textarea"
				    :rows="8"
				    placeholder="发布你的评论"
				    v-model="parentValue"
				    maxlength="500"
				    show-word-limit
			    ></el-input>
		    </div>
		    <div class="custom-dialog-footer">
			    <el-button type="primary" @click="addCommand">发表评论</el-button>
		    </div>
	    </div>
    </div>

</template>

<script>
    // import Details from "./components/Details.vue"
    import Collection from "./components/Collection.vue"
    // import reply from "./components/reply.vue"
    import articleDetailsPage from "./components/articleDetailsPage";
    import articlePersonalInformation from  "./components/articlePersonalInformation"
    export default {
        components: {
            // Details,
            Collection,
            // reply,
            articleDetailsPage,
            articlePersonalInformation,

        },

        computed: {

        },
	    data () {
            return {
                content:{},
                words: '',
                user:{
						headIco:"",
						nickName:""
					},
	            type : this.$route.params.type,
	            id : this.$route.params.id,
	            showComment: false,
	            dialogPosition: { x: 500, y: 300 },
	            parentValue: '',
	            heightComment: true,
            }
        },
	    directives:{
		    draggable: {
			    bind(el) {
				    el.style.position = 'fixed';
				    el.style.cursor = 'move';

				    let offsetX, offsetY;
				    let isDragging = false;

				    el.addEventListener('mousedown', startDrag);

				    function startDrag(e) {
					    isDragging = true;
					    offsetX = e.clientX - el.getBoundingClientRect().left;
					    offsetY = e.clientY - el.getBoundingClientRect().top;

					    document.addEventListener('mousemove', onDrag);
					    document.addEventListener('mouseup', stopDrag);
				    }

				    function onDrag(e) {
					    if (isDragging) {
						    let left = e.clientX - offsetX;
						    let top = e.clientY - offsetY;

						    // 限制位置不超出页面边界
						    left = Math.max(0, Math.min(left, window.innerWidth - el.offsetWidth));
						    top = Math.max(0, Math.min(top, window.innerHeight - el.offsetHeight));

						    el.style.left = left + 'px';
						    el.style.top = top + 'px';
					    }
				    }

				    function stopDrag() {
					    isDragging = false;
					    document.removeEventListener('mousemove', onDrag);
					    document.removeEventListener('mouseup', stopDrag);
				    }
			    },
		    },

	    },
        mounted:function(){
            if (this.type == 1) {
                this.getArticleInfo();
            }else if(this.type == 2){
                this.getKnowledgeInfo();
            }else if(this.type == 3){
                this.getHeadLineInfo();
            }
	        // 在组件挂载后绑定滚动事件
	        window.addEventListener('scroll', this.handleScroll);
        },
	    destroyed() {
		    // 在组件销毁时移除滚动事件，以防止内存泄漏
		    window.removeEventListener('scroll', this.handleScroll);
	    },
        methods:{
	        addCommand() {
				this.$EventBus.$emit('addCommand');
	        },

	        handleScroll() {
		        const targetElement = document.getElementById('target');

		        // 确保目标元素存在
		        if (!targetElement) {
			        return;
		        }

		        // 获取页面滚动时顶部与目标元素顶部的距离
		        const distanceFromTop = window.scrollY || document.documentElement.scrollTop;
		        const targetDistanceFromTop = targetElement.offsetTop;
		        if (distanceFromTop < (targetDistanceFromTop - 650)){
			        this.heightComment = true;
		        }else {
			        this.heightComment = false
		        }
	        },
	        updateShowComment(value) {
		        this.showComment = value; // 更新值 showComment
	        },
            getArticleInfo:function(){
                var that = this;
                this.newApi.getArticleInfo({
                    id:that.id
                }).then((res)=>{
                    that.content = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            getKnowledgeInfo:function(){
                var that = this;
                this.newApi.getKnowledgeInfo({
                    id:that.id
                }).then((res)=>{
                    that.content = res.data;
                    if(res.data.msg != ""){
                        that.utils.msg(res.data.msg);
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            getHeadLineInfo:function (){
                var that = this;
                this.newApi.getHeadLineInfo({
                    id:that.id
                }).then((res)=>{
                    that.content = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 点击logo回退到首页
            add_return:function (){
                this.$router.push({ path: '/' });
            },
        }
    }
</script>

<style scoped>

.custom-dialog {
	z-index: 9999;
	position: fixed;
	width: 800px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.custom-dialog-header {
	background-color: #f0f0f0;
	padding: 20px;
	cursor: move;
	user-select: none;
}
.custom-dialog-header:hover{
	background-color: #c1c1c1;
}

.custom-dialog-title {
	font-size: 16px;
	font-weight: bold;
}

.custom-dialog-close {
	float: right;
	cursor: pointer;
	font-size: 25px;
	font-weight: bold;
	margin-top: -8px;
}

.custom-dialog-body {
	padding: 5px;background-color: #f0f0f0;
}

.custom-dialog-footer {
	background-color: #f0f0f0;
	padding: 10px;
	text-align: right;
}
</style>
